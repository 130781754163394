<template>
  <div>
    <div class="nav">
      <img
        v-if="!sy"
        class="nav_background"
        src="@/assets/img/tourismManagementImg/企业微信截图_1699434203479.png"
        alt=""
      />
      <el-carousel :interval="5000" height="41.66667vw" arrow="never" v-else>
        <el-carousel-item v-for="(item, index) in swiperList" :key="index">
          <img :src="item.imgSrc" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div class="nav_header">
        <dl>
          <dt>
            <img
              src="@/assets/img/tourismManagementImg/1699427720494.jpg"
              alt=""
            />
          </dt>
          <dd>&nbsp;&nbsp;&nbsp;&nbsp;国家文化发展国际战略研究院</dd>
        </dl>
        <div class="nav-rt">
          <ul>
            <li
              v-for="(item, index) in navList"
              :key="index"
              @mouseover="show(index)"
              @mouseleave="hide(index)"
            >
              <router-link
                style="width: 7.8125vw"
                class="ellipsis"
                :to="item.link"
                :class="
                  index == activeIdx && item.children.length > 0 ? 'hover' : ''
                "
                >{{ item.name }}</router-link
              >
              <div
                v-if="item.children.length > 0"
                :class="index == activeIdx ? 'show link2' : 'hide link2'"
                :style="{ left: -400 - index * 40 + 'px' }"
              >
                <div class="lf">
                  <div class="pic">
                    <img src="../../assets/img/banner.jpg" alt="" />
                  </div>
                </div>
                <div class="rt">
                  <span v-for="(citem, cindex) in item.children" :key="cindex">
                    <router-link
                      :to="citem.link"
                      target="_blank"
                      style="width: 7.8125vw"
                      class="ellipsis"
                      >{{ citem.name }}</router-link
                    >
                  </span>
                </div>
              </div>
            </li>
          </ul>
          <div class="search rt">
            <a class="search-search">
              <img
                src="../../assets/img/search.png"
                class="top_search"
                v-if="flag"
              />
              <img
                v-if="!flag"
                src="../../assets/img/redSearch.png"
                class="scroll_search"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="context">
        <p>“经济韧性与全球合作：数字时代的服务贸易”</p>
        <p>第 十 六 届 国 际 服 务 贸 易 论 坛 圆 满 举 办</p>
      </div>
      <div class="lmy-right-list" v-if="tabIsShow">
        <div class="zlm">
          <p class="news 3">{{ $t("newsInformation") }}</p>
          <p>
            <span><img src="../../assets/img/newsIcon.png" /></span
            >{{ $t("newsInformation_1") }}
          </p>
        </div>
        <div class="lmlb">
          <ul>
            <li
              v-for="(item, index) in tablist"
              :key="index"
              :class="$route.query.tabType == item.type ? 'current' : ''"
              id="item"
              @click="wrapLm(item)"
            >
              <span>{{ item.name }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <router-view />
    <div class="bottom">
      <div class="bottom_header">
        <dl>
          <dt>
            <img
              src="@/assets/img/tourismManagementImg/1699427720494.jpg"
              alt=""
            />
          </dt>
          <dd>&nbsp;&nbsp;&nbsp;&nbsp;国家文化发展国际战略研究院</dd>
        </dl>
        <ul>
          <li>关于研究院</li>
          <li>新闻动态</li>
          <li>科研团队</li>
          <li>高端学术论团</li>
          <li>科学研究</li>
          <li>学术成果</li>
          <li>学术动态</li>
          <li>国际合作</li>
        </ul>
      </div>
      <dl class="bottom_links">
        <dt>快速链接</dt>
        <dd>
          <p>二外就业网</p>
          <p>校友网</p>
          <p>二外招生网</p>
          <p>中华人民共和国旅游部</p>
        </dd>
      </dl>
      <dl class="bottom_weChat">
        <dt>
          <img
            src="@/assets/img/tourismManagementImg/1699427834294.jpg"
            alt=""
          />
        </dt>
        <dd>关注微信公众号</dd>
      </dl>
      <div class="bottom_mask">
        <span>
          北京第二外语学院©版权所有 京ICP备：05067963号
          文保网安备案号：110402430076 Copyright 2001-2010 B.I.S.U. all rights
          reserved
        </span>
        <img src="@/assets/img/tourismManagementImg/bottom.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      flag: true,
      activeIdx: null,
      tablist: [
        { name: "新闻资讯", type: "xwzx", path: "/newsUpdates" },
        { name: "国家级", type: "gjj", path: "/nationalLevel" },
        { name: "省部级", type: "sbj", path: "/provincialDepartment" },
        { name: "地厅级", type: "dtj", path: "/departmentLevel" },
        { name: "重点专项研究任务", type: "zdzxyjrw", path: "/researchTasks" },
        { name: "通知公告", type: "tzgg", path: "/noticeAndAnnouncement" },
        { name: "媒体报道", type: "mtbd", path: "/mediaCoverage" },
      ],
      navList: [
        {
          id: "0",
          name: "首页",
          link: "/nationalCulturalDevelopmentHome?routeType=2",
          children: [],
        },
        {
          id: "1",
          name: "关于研究院",
          link: "/researchInstitute?routeType=2",
          children: [],
        },
        {
          id: "2",
          name: "新闻动态",
          link: "/newsUpdates?routeType=2&tabIsShow=true&tabType=xwzx",
          children: [],
        },
        {
          id: "3",
          name: "科研团队",
          link: "/researchTeam?routeType=2",
          children: [],
        },
        {
          id: "4",
          name: "高端学术论坛",
          link: "/highEnd?routeType=2",
          children: [],
        },
        {
          id: "5",
          name: "科学研究",
          link: "",
          children: [
            {
              id: "51",
              name: "国家级",
              link: "/nationalLevel?routeType=2&tabIsShow=true&tabType=gjj",
            },
            {
              id: "52",
              name: "省部级",
              link: "/provincialDepartment?routeType=2&tabIsShow=true&tabType=sbj",
            },
            {
              id: "53",
              name: "地厅级",
              link: "/departmentLevel?routeType=2&tabIsShow=true&tabType=dtj",
            },
            {
              id: "54",
              name: "重点专项研究任务",
              link: "/researchTasks?routeType=2&tabIsShow=true&tabType=zdzxyjrw",
            },
          ],
        },
        {
          id: "6",
          name: "学术成果",
          link: "/achievement?routeType=2",
          children: [],
        },
        {
          id: "7",
          name: "学术动态",
          link: "/academicTrend?routeType=2",
          children: [],
        },
        {
          id: "8",
          name: "国际合作",
          link: "/cooperate?routeType=2",
          children: [],
        },
      ],
      swiperList: [
        {
          imgSrc: require("../../assets/img/tourismManagementImg/企业微信截图_1699434203479.png"),
        },
        {
          imgSrc: require("../../assets/img/tourismManagementImg/1699688655453.jpg"),
        },
        {
          imgSrc: require("../../assets/img/tourismManagementImg/1699427795970.jpg"),
        },
        { imgSrc: require("../../assets/img/tourismManagementImg/3.jpg") },
      ],
    };
  },
  computed: {
    tabIsShow() {
      if (this.$route.query?.tabIsShow == "true") {
        return this.$route.query.tabIsShow;
      }
      return false;
    },
    sy() {
      if (this.$route.name == "nationalCulturalDevelopmentHome") {
        return true;
      }
      return false;
    },
  },
  methods: {
    wrapLm(item) {
      this.$router.push({
        path: item.path,
        query: {
          routeType: 2,
          tabIsShow: true,
          tabType: item.type,
        },
      });
    },
    goToSearch() {},
    show(idx) {
      this.activeIdx = idx;
    },
    hide() {
      this.activeIdx = null;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
body {
  width: 100%;
  height: 100%;
}
.nav {
  width: 100%;
  height: 800px;
  position: relative;
  color: #fff;
}
.nav_background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
::v-deep .el-carousel {
  width: 100%;
  height: 100%;
  position: absolute;
}
::v-deep .el-carousel__container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  img {
    width: 100%;
    height: 100%;
  }
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.nav_header {
  width: 100%;
  height: 100px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid #fff;
}
.nav_header dl {
  height: 100%;
  display: flex;
}
.nav_header dl dt {
  margin-top: 25px;
  margin-left: 50px;
  margin-right: 10px;
}
.nav_header dl dd {
  margin-top: 25px;
  margin-bottom: 25px;
  padding-top: 15px;
  border-left: 2px solid #fff;
}
.nav_header ul {
  height: 100px;
  display: flex;
  align-content: center;
}
.nav_header ul li {
  height: 100px;
  width: 100px;
  line-height: 100px;
  list-style: none;
  margin-right: 20px;
  font-size: 22px;
  position: relative;
}
.nav_header ul li a {
  //   width: 100px;
  font-size: 16px;
  display: inline-block;
}
ul li a:before {
  content: "";
  border-left: 2px solid #fff;
  height: 25px;
  transition: all 0.2s ease-out 0s;
  transform: scaleY(0);
  position: absolute;
  left: 30%;
  top: 75%;
  transform-origin: center top;
}
ul > li > a:after {
  content: "";
  border-bottom: 2px solid #fff;
  margin-top: 5px;
  display: block;
  width: 60%;
  transition: all 0.2s ease-out 0s;
  transform: scaleX(0);
  position: absolute;
  top: 68%;
}
ul > li > a.hover:before {
  transform: none;
}
ul > li > a.hover:after {
  transform: none;
}
.context {
  width: 100%;
  margin-top: 330px;
}
.context p:first-child {
  width: 100%;
  text-align: center;
  font-size: 40px;
}
.context p:nth-child(2) {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}
.nav-rt {
  display: flex;
  align-items: center;
  padding-right: 100px;
}
.link2 {
  padding: 40px;
  border-top: 2px solid #94070a;
  width: 900px;
  position: absolute;
  top: 100%;
  background: #fff url("../../assets/img/ernavBg.png") center bottom no-repeat;
  background-size: contain;
}
.link2 .lf {
  float: left;
  display: flex;
  width: 40%;
}
.link2 .lf .pic {
  width: 290px;
  height: 142px;
}
.link2 .lf .pic img {
  width: 100%;
  height: 100%;
}
.link2 .rt {
  float: right;
  width: 60%;
  text-align: left;
  line-height: 200%;
}
.nav-rt .link2 .rt a {
  color: #333;
  line-height: 200%;
  width: 33.33%;
  font-size: 16px;
  margin-top: 10px;
  display: inline-block;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.nav-rt .link2 .rt a:hover {
  color: #94070a;
}
.lmy-right-list {
  display: flex;
  background: white;
  position: absolute;
  left: 230px;
  bottom: 0;
  z-index: 2;
  border-bottom: 1px solid #eee;
}
.lmy-right-list .zlm {
  width: 300px;
  padding: 20px 40px 0 40px;
  .news {
    font-size: 56px;
    text-align: center;
    color: #a12916;
  }
}
.lmy-right-list p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  text-align: center;
  height: 45px;
  line-height: 45px;
  color: #a12916;
}
.lmy-right-list p span img {
  margin-right: 5px;
}
.lmy-right-list ul {
  display: flex;
  width: 100%;
  height: 128px;
}
.lmy-right-list ul li {
  height: 128px;
  line-height: 128px;
  text-align: center;
  font-size: 20px;
  width: 166px;
  span {
    color: #333;
    cursor: pointer;
  }
}
.lmy-right-list ul li.current {
  background: #a12916;
  span {
    color: #fff;
    cursor: pointer;
  }
}
.lmy-right-list ul li:hover {
  background: #a12916;
  span {
    color: #fff;
    cursor: pointer;
  }
}
/* `----------------------` */

.bottom {
  width: 100%;
  height: 450px;
  position: relative;
  color: #fff;
  background: #a12916;
}
.bottom_header {
  width: 100%;
  height: 100px;
  display: flex;
  position: relative;
  border-bottom: 1px solid #fff;
}
.bottom_header dl {
  height: 100%;
  display: flex;
}
.bottom_header dl dt {
  margin-top: 25px;
  margin-left: 50px;
  margin-right: 10px;
}
.bottom_header dl dd {
  margin-top: 25px;
  margin-bottom: 25px;
  padding-top: 15px;
  border-left: 2px solid #fff;
}
.bottom_header ul {
  float: left;
  position: absolute;
  top: 25px;
  right: 20px;
  z-index: 1;
}
.bottom_header ul li {
  height: 50px;
  line-height: 50px;
  float: left;
  list-style: none;
  margin-right: 30px;
}
.bottom_mask {
  width: 100%;
  height: 70px;
  background: #952210;
  position: absolute;
  bottom: 0;
}
.bottom_mask span {
  font-size: 14px;
  color: #c38f89;
  line-height: 70px;
  margin-left: 100px;
}
.bottom_mask img {
  height: 50px;
  margin-top: 10px;
  position: absolute;
  right: 100px;
}
.bottom_links {
  width: 700px;
  height: 250px;
  margin-left: 100px;
  margin-top: 50px;
  float: left;
}
.bottom_links dd {
  width: 100%;
  margin-top: 24px;
}
.bottom_links dd p {
  width: 300px;
  height: 50px;
  padding-left: 24px;
  line-height: 50px;
  border: 1px solid #c38f89;
  color: #c38f89;
  float: left;
  margin: 0 16px 16px 0;
}
.bottom_weChat {
  width: 200px;
  float: right;
  margin-top: 90px;
}
.bottom_weChat dt {
  width: 100px;
  height: 100px;
  border: 1px solid #c38f89;
  padding: 5px;
}
.bottom_weChat dt img {
  width: 100px;
  height: 100px;
}
.bottom_weChat dd {
  margin-top: 24px;
}
</style>
